<template>
  <div class="layout-padding">
    <div class="layout-padding-auto layout-padding-view">

      <el-table :data="data.tableData" style="width: 100%">
        <el-table-column label="标题" show-overflow-tooltip width="240"    >
          <template #default="scope">
            {{ scope.row.title?.split("___")[0]??""}}
          </template>
        </el-table-column>
        <el-table-column prop="resourceNo" label="预约人" width="140"   show-overflow-tooltip >
          <template #default="scope">
            {{ scope.row.content?.split("___")[0]??""}}
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.regionId" label="手机" width="180" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.content?.split("___")[1]??"" }}
          </template>
        </el-table-column>
        <el-table-column prop="startPrice" label="预约时间" width="140" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.content?.split("___")[2]??"" }}
          </template>
        </el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" width="100" show-overflow-tooltip />

      </el-table>
      <div class="user-info-list-page">
        <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>

    <!-- 交易双方  -->
    <!-- 编辑、新增  -->
    <!--    <form-dialog ref="formDialogRef" @refresh="getDataList(false)" />-->
    <agreement-trade-files-dialog ref="agreementTradeFilesDialogRef" @refresh="pageChange" />
  </div>
</template>

<script setup  name="biddingApply">
import {reactive, ref, defineAsyncComponent, onMounted, computed} from 'vue'
import { useDict, findLabel} from '@/hooks/dict';

import CommonService from "@/service/CommonService";
import {useRouter} from "vue-router/composables";

const router = useRouter();
const AgreementTradeFilesDialog = defineAsyncComponent(() => import('@/components/AuctionTradeFiles.vue'));
// // 定义查询字典

const { transaction_way, land_area, land_purpose, land_owner_type, transaction_person_type } = useDict(
    'transaction_way',
    'land_area',
    'land_purpose',
    'land_owner_type',
    'transaction_person_type'
);

// 定义变量内容
// const formDialogRef = ref();
//
// const fileDialogRef = ref();
const agreementTradeFilesDialogRef = ref();


const showComplementary = (row) => {
  let time = new Date().getTime();
  return time > row.auctionEndTime
      && row.transactionStatus === "processing"
      && row.maxPriceUserId === localStorage.getItem('user_id');
} ;

const data = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  tableData: [
  ]
})

const pageChange = (page) =>{
  loadData(page);
}
const defaultLandQueryParams = ref({
  title: '',
  current: 1,
  size: 30,
  type: 99

});
onMounted(() => {
  loadData(1);
});

const isEnd = (row) =>{
  let date = new Date()
  return row.applyEndTime < date.getTime();
}

const   toDetail=(landInfo)=>{
  if(landInfo.auctionInfo &&  landInfo.transactionWay === "listing"){
    router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
  }
  else if( landInfo.transactionWay === "auction" && landInfo.auctionInfo){
    router.push("/land/auction/"+landInfo.id);
  }
  else{
    router.push("/land/detail/"+landInfo.id);
  }
}
const loadData = (page) =>{



  CommonService.getUserInfo().then(ress => {
    if (ress && ress.data) {
      defaultLandQueryParams.value.current = page
      defaultLandQueryParams.value.title = "__"+ress.data.sysUser.userId+"__"
      CommonService.getLandNews(defaultLandQueryParams.value).then((res)=>{
        if(res.code === 0){

          data.tableData = res.data.records;
        }
      })
    }
  })
}

//
const onSnedApply = async (params) => {

  agreementTradeFilesDialogRef.value.openDialog(params, 'listing');
};



</script>
